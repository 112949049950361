<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <DashboardBody :class="{DashboardBody: isActive}" />
  </div>
</template>
<script>
import Sidebar from './Starter/Sidebar'
import DashboardBody from './Dashboard/DashboardBody'
export default {
  name: 'Dashboard',
  components: {
    Sidebar,
    DashboardBody
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    }
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
